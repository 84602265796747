<template>
  <b-row class="match-height">
    <b-col lg="6">
      <b-card title="Settings">
        <b-form @keyup.enter.prevent="" @submit.prevent="updateSetting">
          <b-row>
            <b-col cols="12" v-for="(setting, id) in settings" :key="id">
              <b-form-group
                :label="Title(setting.setting_key)"
                :label-for="setting.setting_key"
                label-cols-md="4"
              >
                <b-form-input
                  :id="setting.setting_key"
                  :placeholder="setting.setting_key"
                  required="required"
                  v-model="setting.setting_value"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"

const VersionCheck = ""
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  data() {
    return {
      VersionCheck,
      userToken: this.$cookies.get("userToken"),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.userToken}`,
      },
      settings: [],
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    settingsCheck() {
      axios
        .get("https://api.geolims.com/invoice_service/InvoiceVersion", {
          headers: this.headers,
        })
        .then(response => {
          if (response.data.Status === 1 && response.data.Payload.length > 0) {
            this.modalShow = true
            this.VersionCheck = response.data.Payload[0].invoiceVersionNumber
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Settings Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Settings Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    saveSettingsProcess() {
      const body = {
        invoiceVersionNumber: this.VersionCheck,
      }
      axios
        .post(
          "https://api.geolims.com/invoice_service/changeInvoiceVersion",
          body,
          { headers: this.headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.modalShow = false
            this.InvoiceNumber = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Save Settings Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Save Settings Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Save Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    async getSetting() {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_SETTING}`)
      this.settings = res.data.data
      console.log(this.settings)
    },
    async updateSetting() {
      axios.put(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_UPDATE_SETTING}`, { settings: this.settings }).then(response => {
        console.log(response)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Save Settings Success",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      }).catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Save Settings Failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    Title(inputString) {
      const words = inputString.split('_')
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      const result = capitalizedWords.join(' ')
      return result
    },
  },
}
</script>
